body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background: #f3f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header a, .MuiDrawer-paper a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	-webkit-tap-highlight-color: transparent !important;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	 user-select: none;
}


/* Product Swiper */
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.productSwiper {
  border-radius: 30px 30px 0 0;
  max-height: auto !important;
  width: 100%;
}

.productSwiper .swiper-slide {
  width: 100%;
  height: 100%;
}

.productSwiperThumbs {
  border-radius: 0 0 30px 30px;
  height: 80px !important;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.productSwiperThumbs .swiper-slide {
  width: 25%;
  height: 100% !important;
  opacity: 0.4;
  margin: 10px 10px 0 0 !important;
}

.productSwiperThumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Map Marker Info Window */
.gm-style .gm-style-iw-c {
  background: #00873A ;
  border-radius: 30px;
  padding: 15px;
}

.gm-style .gm-style-iw-t::after { 
  background: linear-gradient(45deg,rgba(0, 135, 58,1) 50%,rgba(0, 135, 58,0) 51%,rgba(0, 135, 58,0) 100%);
}

.gm-ui-hover-effect {
  display: none !important;
  right: 2px !important;
}